.map {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.leaflet-container {
  flex: 4;
}

p {
  margin: 20px;
}

pre {
  flex: 1;
  overflow: auto;
}

.react-json-view {
  flex: 1;
  overflow: auto;
}

.popover {
  position: fixed;
  left: 0;
  top: 0;
  margin: 80px 0 0 12px;
  border-radius: 4px !important;
  background-color: #ffffffaa !important;
}

.popup-body {
  padding: 5px 20px;
}

.popup-heading {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.popup-btn {
  padding: 5px;
  margin: 10px;
  font-size: 14px;
  border: 1px solid black;
  border-radius: 4px;
  background-color: white;
}

.popup-btn:hover {
  background-color: #f4f4f4;
}

.popup-btn:active {
  background-color: white;
}
.login-popover {
  margin-top: 0;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.login-form-row {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.login-form-row-label {
  margin: 0;
}

.login-error {
  font-size: 10px;
  color: red;
  margin: 0px;
}

.threshold {
  margin: 0;
  padding: 5px;
  background-color: #fff;
  display: flex;
  align-items: center;
  border-radius: 5px 5px 5px 5px;
  border: 1px solid black;
}

.thresholdTerm {
  background: #fff;
  width: 100%;
  border: 0;
}

.customFormSelect {
  padding: 0rem !important;
  font-size: inherit !important;
}

.thresholdTerm:focus {
  outline: none;
}

.spinner {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  background: #ffffff66;
}

.spinner-border {
  position: fixed;
  left: 50%;
  top: 45%;
}

.geosearch {
  position: fixed !important;
  left: 80px;
}

.glass {
  width: 280px;
  height: 30px;
}

.reset {
  background: #0d6efd;
  color: white;
  border-radius: 5px 5px 5px 5px;
  border: 1px solid #0d6efd;
}

.results {
  background: white;
  border-radius: 5px 5px 5px 5px;
  cursor: pointer;
}

.leaflet-bar-part {
  display: none !important;
}
