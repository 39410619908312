.search-container {
  z-index: 1001;
  position: fixed;
  overflow: visible;
  top: 10px;
  left: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 2px;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

#search-popover {
  margin: 0;
  max-width: 40vh;
  width: 40vh;
}

.popover-body {
  display: flex;
  flex-direction: column;
}

.error-msg{
  color: red;
  margin-bottom: 10px;
}

.options {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
}

p {
  margin: 0 0 0 15px;
}

.search {
  margin: 0;
  padding: 5px;
  background-color: #fff;
  display: flex;
  align-items: center;
  border-radius: 5px 5px 5px 5px;
  border: 1px solid black;
}

.search-icon {
  margin: 0 8px;
  color: grey;
  cursor: pointer;
}

.searchTerm {
  background: #fff;
  width: 100%;
  border: 0;
}

.searchTerm:focus {
  outline: none;
}
